import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env;

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpeg`} alt="" />
      </Link>
      <header>
        <h3>Vicente Daie Pinilla</h3>
        <p className="no-margin-p">27 años | <img height="15px" src={`${PUBLIC_URL}/images/chile.png`} alt="" /></p>
        <p><a href="mailto:vdaiep@gmail.com">vdaiep@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>Sobre mí</h2>
      <p className="justified">Soy Ingeniero Civil en Computación de la Universidad de
        Chile, y también Licenciado en Astronomía y en Física de la misma institución.
      </p>
      <p className="justified">Actualmente soy miembro del área de Diseño y Programación del Laboratorio de
        Educación del Centro de Modelamiento Matemático (<a rel="noreferrer" target="_blank" href="https://cmmedu.uchile.cl">CMMEdu</a>).
      </p>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Vicente Daie Pinilla <Link to="/">vdaiep.cl</Link>.</p>
    </section>
  </section>
);

export default SideBar;
