const routes = [
  {
    index: true,
    label: 'Vicente Daie Pinilla',
    path: '/',
  },
  {
    label: 'CV',
    path: '/resume',
  },
  {
    label: 'Portafolio',
    path: '/projects',
  },
  {
    label: 'Contacto',
    path: '/contact',
  },
];

export default routes;
